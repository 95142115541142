import React, { useState } from 'react';
import { bool, func, node, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Alert from 'react-s-alert';
import ShortcutService from 'mangools-commons/lib/services/ShortcutService';
import { GlobalHotKeys } from 'react-hotkeys';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import shortcuts, { CLOSE_ALL, SHOW_SHORTCUTS_TOGGLE } from 'constants/Shortcuts';

import { closeAll, showShortcutsMessage } from 'actions/uiActions';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';
import { announcementMesageVisibilitySelector } from 'selectors/announcementsSelectors';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
    anyOverlayVisibleSelector,
} from 'selectors/uiSelectors';

const LayoutContainer = props => {
    const [showBanner, setShowBanner] = useState(sessionStorage.getItem('hideBanner') !== 'true');

    const closeBanner = () => {
        sessionStorage.setItem('hideBanner', 'true');
        setShowBanner(false);
    };

    const appClasses = classnames({
        'serpchecker-app': true,
        'is-offline': props.onlineStatus === false,
        'is-messaged': props.topLevelMessageVisible,
        'is-modal-opened': props.anyOverlayVisible,
    });

    const renderShortcuts = () => {
        const { keyMap, handlers } = ShortcutService.getShortcutMapAndHandlers({
            handlers: {
                [CLOSE_ALL]: { func: props.onCloseAll, preventDisable: true },
                [SHOW_SHORTCUTS_TOGGLE]: { func: props.onShowShortcutsMessage },
            },
            areShortcutsDisabled: props.areShortcutsDisabled,
            shortcuts,
        });

        return <GlobalHotKeys allowChanges keyMap={keyMap} handlers={handlers} />;
    };

    return (
        <div className={appClasses}>
            {renderShortcuts()}
            <TopLevelMessageContainer />
            <NavbarContainer currentRoute={props.location.pathname} />
            {showBanner && (
                <div style={{
                    position: 'fixed',
                    top: 75,
                    zIndex: 1000,
                    width: '100%',
                    padding: '5px',
                    backgroundColor: '#ecbe02'
                }}>
                    <div style={{
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        margin: 0,
                        position: 'relative'
                    }}>
                        We are experiencing issues at the moment. We are working on it. Thank you for your patience.
                        <button onClick={closeBanner} style={{
                            position: 'absolute',
                            right: 10,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'transparent',
                            border: 'none',
                            color: 'white',
                            fontSize: '16px',
                            cursor: 'pointer'
                        }}>
                            ×
                        </button>
                    </div>
                </div>
            )}
            {props.children}
            <PanelContainer />
            <ModalContainer />
            <MessageContainer />
            <Overlay mobile onClick={props.onCloseAll} visibility={props.mobileOverlayVisibility} />
            <Overlay mobile={false} onClick={props.onCloseAll} visibility={props.overlayVisibility} />
            <div className="uk-hidden-small">
                <Alert effect="slide" offset={50} position="top-right" stack={{ limit: 3 }} timeout={3500} />
            </div>
        </div>
    );
};

LayoutContainer.propTypes = {
    children: node.isRequired,
    location: shape({ pathname: string }).isRequired,
    mobileOverlayVisibility: bool.isRequired,
    onCloseAll: func.isRequired,
    onlineStatus: bool.isRequired,
    overlayVisibility: bool.isRequired,
    topLevelMessageVisible: bool.isRequired,
    anyOverlayVisible: bool.isRequired,
    onShowShortcutsMessage: func.isRequired,
    areShortcutsDisabled: bool.isRequired,
};

const mapStateToProps = (state, _ownProps) => ({
    mobileOverlayVisibility: mobileOverlayVisibilitySelector(state),
    onlineStatus: onlineStatusSelector(state),
    overlayVisibility: overlayVisibilitySelector(state),
    topLevelMessageVisible: newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    anyOverlayVisible: anyOverlayVisibleSelector(state),
    areShortcutsDisabled: anyOverlayVisibleSelector(state),
});

const mapDispatchToProps = (dispatch, _ownProps) => ({
    onCloseAll() {
        dispatch(closeAll());
    },
    onShowShortcutsMessage() {
        dispatch(showShortcutsMessage());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
